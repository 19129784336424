import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useNavigationItemsStore } from '@core/navigation';
import { useSyncState } from '@core/sync-state';

import type {
  ShowcaseNavigationItemReducer,
  ShowcaseNavigationItemReducersList,
} from 'web/src/modules/home/submodules/showcase/types';
import { ShowcaseNavigationItemId } from 'web/src/modules/home/submodules/showcase/enums';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';

interface ShowcaseNavigationZeroMarginComposable {
  hasZeroMarginNavigationItem: Ref<boolean>;
  addZeroMarginReducer(itemReducers: ShowcaseNavigationItemReducersList): ShowcaseNavigationItemReducersList;
  resumeZeroMarginSyncState(): void;
  pauseZeroMarginSyncState(): void;
}

export default function useShowcaseNavigationZeroMarginComposable(): ShowcaseNavigationZeroMarginComposable {
  const settingsStore = useSportlineSettingsStore();
  const sportsTreeStore = useSportsTreeStore();
  const navigationStore = useNavigationItemsStore();

  const showcaseItems = toRef(navigationStore, 'showcaseItems');
  const hasZeroMargin = toRef(sportsTreeStore, 'hasZeroMargin');
  const zeroMarginUpdateInterval = toRef(sportsTreeStore, 'zeroMarginUpdateInterval');
  const isZeroMarginEnabled = toRef(settingsStore, 'isZeroMarginEnabled');

  const hasZeroMarginNavigationItem = computed<boolean>(() => showcaseItems.value
    .some(({ id }) => (
      id === ShowcaseNavigationItemId.ZERO_MARGIN || id === ShowcaseNavigationItemId.HIGHEST_ODDS
    )));

  const {
    pauseSyncState,
    resumeSyncState,
  } = useSyncState(async (silent) => {
    if (hasZeroMarginNavigationItem.value) {
      await sportsTreeStore.reloadZeroMarginCounters({ silent });
    }
  }, zeroMarginUpdateInterval, { suspendedByDefault: true });

  function isZeroMarginEnabledReducer(): boolean {
    return isZeroMarginEnabled.value && hasZeroMargin.value;
  }

  function addZeroMarginReducer(itemReducers: ShowcaseNavigationItemReducersList): ShowcaseNavigationItemReducersList {
    const deprecatedReducers: ShowcaseNavigationItemReducer[] = itemReducers[ShowcaseNavigationItemId.ZERO_MARGIN] ?? [];
    const reducers: ShowcaseNavigationItemReducer[] = itemReducers[ShowcaseNavigationItemId.HIGHEST_ODDS] ?? [];
    return {
      ...itemReducers,
      [ShowcaseNavigationItemId.ZERO_MARGIN]: [...deprecatedReducers, isZeroMarginEnabledReducer],
      [ShowcaseNavigationItemId.HIGHEST_ODDS]: [...reducers, isZeroMarginEnabledReducer],
    };
  }

  return {
    hasZeroMarginNavigationItem,
    addZeroMarginReducer,
    resumeZeroMarginSyncState: resumeSyncState,
    pauseZeroMarginSyncState: pauseSyncState,
  };
}
