import type { NavigationItemCdnIcon } from '@leon-hub/navigation-config';
import { getNavigationItemIconLocation } from '@leon-hub/navigation-config';
import { Json } from '@leon-hub/utils';

import { useRootStore } from '@core/root';
import { useTheme } from '@core/theme';

import type { ShowCaseNavigationImageItem } from 'web/src/modules/home/submodules/showcase/types';
import { ShowcaseNavigationItemType } from 'web/src/modules/home/submodules/showcase/enums';

interface ShowcaseNavigationImageItemsComposable {
  createImageNavigationItem(iconCdn: NavigationItemCdnIcon, iconStyle?: string): ShowCaseNavigationImageItem;
}

export default function useShowcaseNavigationImageItemsComposable(): ShowcaseNavigationImageItemsComposable {
  const { theme } = useTheme();
  const { imageCdnUrl } = useRootStore();

  function createImageNavigationItem(iconCdn: NavigationItemCdnIcon, iconStyle?: string): ShowCaseNavigationImageItem {
    const iconStyleParsed = iconStyle
      ? Json.parse(iconStyle, { defaultValue: {} }) as Record<string, Record<string, string>>
      : {};
    const iconThemeStyle = iconStyleParsed[theme.value.toLowerCase()] ?? {};

    return {
      is: ShowcaseNavigationItemType.Image,
      props: {
        src: getNavigationItemIconLocation(iconCdn.src, imageCdnUrl, theme.value),
        alt: '',
        style: {
          width: iconCdn.width ?? 'auto',
          height: iconCdn.height ?? '24px',
          ...iconThemeStyle,
        },
      },
    };
  }

  return {
    createImageNavigationItem,
  };
}
