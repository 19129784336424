<script setup lang="ts">
import { toRef } from 'vue';

import HomeHeading from 'web/src/modules/home/components/heading/HomeHeading.vue';
import { SportlineHomeBlockPriority } from 'web/src/modules/home/submodules/sportline/enums';
import SportlineHomeSportElement from 'web/src/modules/home/submodules/sportline/views/SportlineHomeSportElement.vue';

import {
  useHomeSportlineElementSeoMeta,
  useSportlineEventsBlock,
} from './composables';

interface SportlineEventsBlockProps {
  priority?: SportlineHomeBlockPriority;
}

const props = defineProps<SportlineEventsBlockProps>();
const priority = toRef(props, 'priority', SportlineHomeBlockPriority.Live);

const {
  isLiveBlock,
  isPrematchBlock,
  title,
  buttonTitle,
  sportElements,
  secondaryButton,
  pageLocation,
} = useSportlineEventsBlock({ priority });
useHomeSportlineElementSeoMeta({ sportElements });
</script>

<template>
  <div v-auto-id="'SportlineEventsBlock'"
    v-if="isLiveBlock || isPrematchBlock"
    :class="$style['sport-events-block']"
  >
    <div :class="$style['sport-events-block__heading']">
      <HomeHeading
        key="heading"
        :title="title"
        :button-text="buttonTitle"
        :to="pageLocation"
        :secondary-button="secondaryButton"
      />
    </div>

    <SportlineHomeSportElement
      v-for="sportElement in sportElements"
      :key="sportElement.sport.id"
      :sport-element="sportElement"
      :is-market-types-selection-enabled="false"
    />
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .sport-events-block {
    padding: 8px;
    background-color: var(--Layer0);

    &__heading {
      margin: if($isDesktop, 0, 8px 0);
    }
  }
}
</style>
