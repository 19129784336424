<script setup lang="ts">
import SwiperNavigationButtons
  from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import HomeHeading from 'web/src/modules/home/components/heading/HomeHeading.vue';

import HomePromotionsSlide from './HomePromotionsSlide.vue';
import { useHomePromotionsBlock } from './useHomePromotionsBlock';

const {
  promotions,
  allPromotionsRoute,
  openAllPromotions,
  openPromotion,
  getPromotionRoute,
} = useHomePromotionsBlock();
</script>

<template>
  <div v-auto-id="'HomePromotionsBlock'"
    v-if="promotions.length > 0"
    :class="$style['home-promotions']"
  >
    <HomeHeading
      :title="$t('WEB2_HOME_HEADING_PROMOTIONS')"
      :button-text="$t('WEB2_HOME_HEADING_PROMOTIONS_BTN')"
      :to="allPromotionsRoute"
      is-dark
      :class="$style['home-promotions__heading']"
    />
    <VSwiper is-scroll-snap-enabled>
      <VSwiperSlide
        v-for="promotion in promotions"
        :key="promotion.actionUrl ?? promotion.name ?? 'unknown'"
        :class="$style['home-promotions__slide']"
        @click="openPromotion(promotion)"
      >
        <HomePromotionsSlide
          :name="promotion.name"
          :banner-url="promotion.shortBannerUrl"
          :banner-image="promotion.shortBannerImage"
          :description="promotion.shortDescription"
          :is-new="promotion.isNew"
          :to="getPromotionRoute(promotion)"
        />
      </VSwiperSlide>
      <VSwiperSlide
        v-if="promotions.length === 1"
        :class="$style['home-promotions__slide']"
        @click="openAllPromotions"
      >
        <HomePromotionsSlide
          :name="$t('WEB2_HOME_PROMOTIONS_SLIDE_ALL_TTL')"
          :description="$t('WEB2_HOME_PROMOTIONS_SLIDE_ALL_DESC')"
          :banner-url="require('web/src/assets/images/all-promotions.jpg')"
          :to="allPromotionsRoute"
        />
      </VSwiperSlide>
      <template
        v-if="'1'"
        #pagination-footer
      >
        <SwiperNavigationButtons />
      </template>
    </VSwiper>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .home-promotions {
    &__heading {
      padding-right: if($isDesktop, 0, 8px);
    }

    &__slide {
      box-sizing: content-box;
      width: if($isDesktop, 320px, 307px);
      padding: if($isDesktop, 8px 16px 16px 0, 8px 8px 16px);
      font-size: 13px;
      color: var(--TextDefault);

      @if $isDesktop {
        cursor: pointer;

        &:last-of-type {
          padding-right: 0;
        }
      }
    }
  }
}
</style>
