import type { Ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { computed, toRef } from 'vue';

import { IconName } from '@leon-hub/icons';

import { useIsLoggedIn } from '@core/auth';
import { useI18n } from '@core/i18n';

import type { HomeHeadingButton } from 'web/src/modules/home/components/heading/types';
import type { HeadlineMatchesSportListElement } from 'web/src/modules/sportline/submodules/top/types';
import { SportlineHomeBlockPriority } from 'web/src/modules/home/submodules/sportline/enums';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import {
  resolveLivePageLink,
  resolveSportsPageLink,
} from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { useSeparateHeadlineEventsLists } from 'web/src/modules/sportline/submodules/top/composables';

interface UseSportlineEventsBlockProps {
  priority: Ref<SportlineHomeBlockPriority>;
}

interface UseSportlineEventsBlockComposable {
  isLiveBlock: Ref<boolean>;
  isPrematchBlock: Ref<boolean>;
  hasLiveSportEvents: Ref<boolean>;
  hasPrematchSportEvents: Ref<boolean>;
  totalCount: Ref<number>;
  title: Ref<Maybe<string>>;
  buttonTitle: Ref<Maybe<string>>;
  sportElements: Ref<HeadlineMatchesSportListElement[]>;
  secondaryButton: Ref<Maybe<HomeHeadingButton>>;
  pageLocation: Ref<Maybe<RouteLocationRaw>>;
}

export function useSportlineEventsBlock(
  props: UseSportlineEventsBlockProps,
): UseSportlineEventsBlockComposable {
  const { priority } = props;

  const { $translate } = useI18n();
  const { isLoggedIn } = useIsLoggedIn();

  const favoritesEventsStore = useFavoritesEventsStore();
  const favoritesIdsList = toRef(favoritesEventsStore, 'favoritesIdsList');

  const {
    totalLiveCount,
    totalPrematchCount,
    liveHeadlineElements,
    prematchHeadlineElements,
  } = useSeparateHeadlineEventsLists();

  const hasLiveSportEvents = computed<boolean>(() => (liveHeadlineElements.value.length > 0));
  const hasPrematchSportEvents = computed<boolean>(() => (prematchHeadlineElements.value.length > 0));
  const isLiveBlock = computed<boolean>(() => (
    priority.value === SportlineHomeBlockPriority.Live && hasLiveSportEvents.value
  ));
  const isPrematchBlock = computed<boolean>(() => {
    const isPrematchBlockWithLive = priority.value === SportlineHomeBlockPriority.Prematch
      && hasLiveSportEvents.value
      && hasPrematchSportEvents.value;
    const isLiveBlockWithoutLive = priority.value === SportlineHomeBlockPriority.Live
      && !hasLiveSportEvents.value;

    return isPrematchBlockWithLive || isLiveBlockWithoutLive;
  });

  const totalCount = computed<number>(() => {
    if (isLiveBlock.value) {
      return totalLiveCount.value;
    }
    if (isPrematchBlock.value) {
      return totalPrematchCount.value;
    }
    return 0;
  });
  const title = computed<Maybe<string>>(() => {
    if (isLiveBlock.value) {
      return $translate('WEB2_LIVE_SPORTLINE').value;
    }
    if (isPrematchBlock.value) {
      return $translate('WEB2_PREMATCH_SPORTLINE').value;
    }
    return null;
  });
  const buttonTitle = computed<Maybe<string>>(() => {
    if (isLiveBlock.value) {
      return `${$translate('WEB2_HOME_ALL_LIVE_BUTTON').value} (${totalCount.value})`;
    }

    if (isPrematchBlock.value) {
      return `${$translate('WEB2_HOME_ALL_PREMATCH_BUTTON').value} (${totalCount.value})`;
    }

    return null;
  });

  const sportElements = computed<HeadlineMatchesSportListElement[]>(() => {
    if (isLiveBlock.value) {
      return liveHeadlineElements.value;
    }
    if (isPrematchBlock.value) {
      return prematchHeadlineElements.value;
    }
    return [];
  });

  const secondaryButton = computed<Maybe<HomeHeadingButton>>(() => {
    if (!isLiveBlock.value) {
      return null;
    }
    if (!isLoggedIn.value) {
      return null;
    }

    const count = favoritesIdsList.value?.length;
    if (!count) {
      return null;
    }

    return {
      text: `(${count})`,
      to: resolveLivePageLink({ urlName: CustomFilter.Favorites }),
      icon: { name: IconName.STAR_ALT },
    };
  });
  const pageLocation = computed<Maybe<RouteLocationRaw>>(() => {
    if (isLiveBlock.value) {
      return resolveLivePageLink({});
    }
    if (isPrematchBlock.value) {
      return resolveSportsPageLink({});
    }
    return null;
  });

  return {
    isLiveBlock,
    isPrematchBlock,
    hasLiveSportEvents,
    hasPrematchSportEvents,
    totalCount,
    title,
    buttonTitle,
    sportElements,
    secondaryButton,
    pageLocation,
  };
}
