import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import type { NavigationItem } from '@leon-hub/navigation-config';
import { isString } from '@leon-hub/guards';
import {
  getNavigationItemRouteLocation,
} from '@leon-hub/navigation-config';
import { Json, keys } from '@leon-hub/utils';

import { useNavigationItemsStore } from '@core/navigation';
import { useSiteConfigStore } from '@core/site-config';
import { useTheme } from '@core/theme';

import type {
  ShowCaseNavigationItem,
  ShowcaseNavigationItemReducer,
  ShowcaseNavigationItemReducersList,
} from 'web/src/modules/home/submodules/showcase/types';
import { ShowcaseNavigationItemName } from 'web/src/modules/home/submodules/showcase/enums';

import useShowcaseNavigationAnalyticsComposable from './useShowcaseNavigationAnalyticsComposable';
import useShowcaseNavigationFavoriteEventsComposable from './useShowcaseNavigationFavoriteEventsComposable';
import useShowcaseNavigationIconItemsComposable from './useShowcaseNavigationIconItemsComposable';
import useShowcaseNavigationImageItemsComposable from './useShowcaseNavigationImageItemsComposable';
import useShowcaseNavigationZeroMarginComposable from './useShowcaseNavigationZeroMarginComposable';

interface ShowcaseNavigationComposable {
  items: Ref<ShowCaseNavigationItem[]>;
  handleNavigationItemClickAnalytics(name: string): void;
  resumeSyncState(): void;
  pauseSyncState(): void;
}

export default function useShowcaseNavigationComposable(): ShowcaseNavigationComposable {
  const siteConfigStore = useSiteConfigStore();
  const { theme } = useTheme();

  const isPromotionsHighlightLinkEnabled = toRef(siteConfigStore, 'isPromotionsHighlightLinkEnabled');

  const itemReducers = ref<ShowcaseNavigationItemReducersList>({});
  const {
    handleNavigationItemClickAnalytics,
  } = useShowcaseNavigationAnalyticsComposable();
  const {
    createImageNavigationItem,
  } = useShowcaseNavigationImageItemsComposable();
  const {
    createIconNavigationItem,
  } = useShowcaseNavigationIconItemsComposable();
  const {
    addZeroMarginReducer,
    resumeZeroMarginSyncState,
    pauseZeroMarginSyncState,
  } = useShowcaseNavigationZeroMarginComposable();
  const {
    addFavoriteEventsReducer,
  } = useShowcaseNavigationFavoriteEventsComposable();
  const navigationStore = useNavigationItemsStore();
  const showcaseItems = toRef(navigationStore, 'showcaseItems');

  const items = computed<ShowCaseNavigationItem[]>(

    () => showcaseItems.value.map((item: NavigationItem): Maybe<ShowCaseNavigationItem> => {
      const {
        id,
        caption,
        icon,
        iconCdn,
        props,
      } = item;

      const itemReducersKey = keys(itemReducers.value).find((key) => key === id);
      const reducers: ShowcaseNavigationItemReducer[] = itemReducersKey
        ? (itemReducers.value[itemReducersKey] ?? [])
        : [];

      if (reducers.length > 0 && !reducers.every((reducer) => reducer(id))) {
        return null;
      }

      let navIcon: ShowCaseNavigationItem['icon'] = null;
      if (icon) {
        navIcon = createIconNavigationItem(icon);
      } else if (iconCdn) {
        navIcon = createImageNavigationItem(iconCdn, isString(props?.iconStyle) ? String(props.iconStyle) : undefined);
      }

      const className = isString(props?.className) ? props?.className : undefined;
      const routerLinkStyle = isString(props?.routerLinkStyle)
        ? Json.parse(String(props.routerLinkStyle), { defaultValue: {} }) as Record<string, Record<string, string>>
        : {};
      const routerLinkThemeStyle = routerLinkStyle?.[theme.value.toLowerCase()] ?? {};

      return {
        key: `${id}-${theme.value}`,
        name: caption || '',
        className,
        icon: navIcon,
        location: getNavigationItemRouteLocation(item, 'showcase'),
        isHighlighted: className === ShowcaseNavigationItemName.ACTIONS && isPromotionsHighlightLinkEnabled.value,
        isLandingEuroRu: !!process.env.VUE_APP_PRODUCT_LEONRU && className === ShowcaseNavigationItemName.LANDING_EURO,
        routerLinkThemeStyle,
      };
    })
      .filter((item: Maybe<ShowCaseNavigationItem>): item is ShowCaseNavigationItem => !!item),
  );

  function resumeSyncState(): void {
    resumeZeroMarginSyncState();
  }

  function pauseSyncState(): void {
    pauseZeroMarginSyncState();
  }

  // init reducers
  let compiledReducers = itemReducers.value;
  compiledReducers = addZeroMarginReducer(compiledReducers);
  compiledReducers = addFavoriteEventsReducer(compiledReducers);
  itemReducers.value = compiledReducers;

  return {
    items,
    handleNavigationItemClickAnalytics,
    resumeSyncState,
    pauseSyncState,
  };
}
